.navigation {
    display: inline-block;
    margin-top: 20px;

    @include breakpoint(medium-large) {
        margin-top: 0;
    }

    &-wrapper {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        @include breakpoint(medium-large) {
            height: auto;
            overflow: visible;
            opacity: 1;
        }
    }

    &-top {
        display: flex;
        padding: 12px 0;

        @include breakpoint(medium-large) {
            padding: 0;
        }
    }
}