@include breakpoint(large) {
    .site-content {
        width: 100%;
        padding: 50px 0;
    }
}

@include breakpoint(xlarge) {
    .site-content {
        padding: 100px 0;
    }
}