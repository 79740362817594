.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: $transition-default;
    margin: 0 auto;
    background: map-deep-get($colors, "white", "default");
    height: auto;
    border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    overflow: auto;
    max-height: 100vh;
    z-index: 15;

    @include breakpoint(medium-large) {
        margin-top: 20px;
        background: none;
        padding: 0;
        border-bottom: none;
        overflow: visible;

        @include breakpoint(large) {
            margin-top: 35px;
        }

        &--scrolled{
            margin-top: 0;
            background: map-deep-get($colors, "white", "default");
            border-bottom: 1px solid map-deep-get($colors, "gray", "default");
            padding-bottom: 0;
            padding-top: 10px;

            /* stylelint-disable */
            .menu-item--active .menu-item__link::before {
                margin-bottom: 15px;

                @include breakpoint(large) {
                    margin-bottom: 13px;
                }
            }
            /* stylelint-enable */
        }
    }

    &.menu-expanded {
        bottom: 0;

        .navigation-wrapper {
            height: auto;
            opacity: 1;
        }
    }
}