﻿@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700');

body {
    line-height: 1.875em;
    letter-spacing: 0.05em;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.25em;
    letter-spacing: normal;
}

h1, .h1 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}

h2, .h2 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}

h3, .h3 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}

h4, .h4 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}

h5, .h5 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}

h6, .h6 {
    //Insert styling here
    font-size: rem-calc(16px);
    font-weight: 600;
}