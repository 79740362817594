.hamburger{
    display: inline-flex;
    align-items: center;

    &-label{
        color: map-deep-get($colors, "primary", "default");
        text-transform: uppercase;
        margin-right: 10px;
        font-size: rem-calc(14px);
        font-weight: 800;
    }
}