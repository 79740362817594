.logo {
    display: inline-block;
    max-width: 149px;
    width: 100%;
    margin-right: 10px;

    @include breakpoint(medium) {
        max-width: 125px;
        margin-right: 20px;
    }

    @include breakpoint(large) {
        max-width: 205px;
        margin-right: 10px;
    }
}