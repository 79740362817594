.main {
    padding-top: 60px;
    //overflow: hidden;
    max-width: 100%;
    min-height: 100vh;
    //fix voor child 100%
    height: 1px;

    @include breakpoint(medium) {
        padding-top: 120px;
    }

    &__inner {
        @include breakpoint(medium) {
            height: 100%;
        }
    }
}