.footer-menu {

    .kvk {
        margin-right: 1.75em;
        font-size: rem-calc(13px);

        &::after {
            content: "\2022";
            display: inline-block;
            font-size: #{(16px / 16px) * 1rem};
            margin-left: 1em;
        }
    }

    .menu-item {
        display: inline-block;
        padding: 0;

        a {
            color: map-deep-get($colors, "primary", "default");

            &::before {
                content: none;
            }
        }
    }
}