.sitemap {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    /* stylelint-disable */
    // dynamische content
    a:not(.button) {
        color: map-deep-get($colors, "primary", "default");
        text-decoration: underline;
    }
    /* stylelint-enable */
}