/* stylelint-disable */
//dynamische knop
.fetchimage-wrapper {
    position: relative;
    height: 100%;
    z-index:10;

    .button--delete{
        bottom: 20px !important;
        top: auto !important;
    }
}
/* stylelint-enable */