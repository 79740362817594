/**
 * Foundation for Sites by ZURB
 * Version 6.4.4-rc1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700");
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&medium-large=48em&large=64em&xlarge=75em&xxlarge=90em&xxxlarge=100em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12, .grid-x > .small-13, .grid-x > .small-14, .grid-x > .small-15, .grid-x > .small-16, .grid-x > .small-17, .grid-x > .small-18, .grid-x > .small-19, .grid-x > .small-20, .grid-x > .small-21, .grid-x > .small-22, .grid-x > .small-23, .grid-x > .small-24 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12, .grid-x > .medium-13, .grid-x > .medium-14, .grid-x > .medium-15, .grid-x > .medium-16, .grid-x > .medium-17, .grid-x > .medium-18, .grid-x > .medium-19, .grid-x > .medium-20, .grid-x > .medium-21, .grid-x > .medium-22, .grid-x > .medium-23, .grid-x > .medium-24 {
    flex-basis: auto; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-large-shrink, .grid-x > .medium-large-full, .grid-x > .medium-large-1, .grid-x > .medium-large-2, .grid-x > .medium-large-3, .grid-x > .medium-large-4, .grid-x > .medium-large-5, .grid-x > .medium-large-6, .grid-x > .medium-large-7, .grid-x > .medium-large-8, .grid-x > .medium-large-9, .grid-x > .medium-large-10, .grid-x > .medium-large-11, .grid-x > .medium-large-12, .grid-x > .medium-large-13, .grid-x > .medium-large-14, .grid-x > .medium-large-15, .grid-x > .medium-large-16, .grid-x > .medium-large-17, .grid-x > .medium-large-18, .grid-x > .medium-large-19, .grid-x > .medium-large-20, .grid-x > .medium-large-21, .grid-x > .medium-large-22, .grid-x > .medium-large-23, .grid-x > .medium-large-24 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12, .grid-x > .large-13, .grid-x > .large-14, .grid-x > .large-15, .grid-x > .large-16, .grid-x > .large-17, .grid-x > .large-18, .grid-x > .large-19, .grid-x > .large-20, .grid-x > .large-21, .grid-x > .large-22, .grid-x > .large-23, .grid-x > .large-24 {
    flex-basis: auto; } }

@media screen and (min-width: 75em) {
  .grid-x > .xlarge-shrink, .grid-x > .xlarge-full, .grid-x > .xlarge-1, .grid-x > .xlarge-2, .grid-x > .xlarge-3, .grid-x > .xlarge-4, .grid-x > .xlarge-5, .grid-x > .xlarge-6, .grid-x > .xlarge-7, .grid-x > .xlarge-8, .grid-x > .xlarge-9, .grid-x > .xlarge-10, .grid-x > .xlarge-11, .grid-x > .xlarge-12, .grid-x > .xlarge-13, .grid-x > .xlarge-14, .grid-x > .xlarge-15, .grid-x > .xlarge-16, .grid-x > .xlarge-17, .grid-x > .xlarge-18, .grid-x > .xlarge-19, .grid-x > .xlarge-20, .grid-x > .xlarge-21, .grid-x > .xlarge-22, .grid-x > .xlarge-23, .grid-x > .xlarge-24 {
    flex-basis: auto; } }

@media screen and (min-width: 90em) {
  .grid-x > .xxlarge-shrink, .grid-x > .xxlarge-full, .grid-x > .xxlarge-1, .grid-x > .xxlarge-2, .grid-x > .xxlarge-3, .grid-x > .xxlarge-4, .grid-x > .xxlarge-5, .grid-x > .xxlarge-6, .grid-x > .xxlarge-7, .grid-x > .xxlarge-8, .grid-x > .xxlarge-9, .grid-x > .xxlarge-10, .grid-x > .xxlarge-11, .grid-x > .xxlarge-12, .grid-x > .xxlarge-13, .grid-x > .xxlarge-14, .grid-x > .xxlarge-15, .grid-x > .xxlarge-16, .grid-x > .xxlarge-17, .grid-x > .xxlarge-18, .grid-x > .xxlarge-19, .grid-x > .xxlarge-20, .grid-x > .xxlarge-21, .grid-x > .xxlarge-22, .grid-x > .xxlarge-23, .grid-x > .xxlarge-24 {
    flex-basis: auto; } }

@media screen and (min-width: 100em) {
  .grid-x > .xxxlarge-shrink, .grid-x > .xxxlarge-full, .grid-x > .xxxlarge-1, .grid-x > .xxxlarge-2, .grid-x > .xxxlarge-3, .grid-x > .xxxlarge-4, .grid-x > .xxxlarge-5, .grid-x > .xxxlarge-6, .grid-x > .xxxlarge-7, .grid-x > .xxxlarge-8, .grid-x > .xxxlarge-9, .grid-x > .xxxlarge-10, .grid-x > .xxxlarge-11, .grid-x > .xxxlarge-12, .grid-x > .xxxlarge-13, .grid-x > .xxxlarge-14, .grid-x > .xxxlarge-15, .grid-x > .xxxlarge-16, .grid-x > .xxxlarge-17, .grid-x > .xxxlarge-18, .grid-x > .xxxlarge-19, .grid-x > .xxxlarge-20, .grid-x > .xxxlarge-21, .grid-x > .xxxlarge-22, .grid-x > .xxxlarge-23, .grid-x > .xxxlarge-24 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 4.16667%; }

.grid-x > .small-2 {
  width: 8.33333%; }

.grid-x > .small-3 {
  width: 12.5%; }

.grid-x > .small-4 {
  width: 16.66667%; }

.grid-x > .small-5 {
  width: 20.83333%; }

.grid-x > .small-6 {
  width: 25%; }

.grid-x > .small-7 {
  width: 29.16667%; }

.grid-x > .small-8 {
  width: 33.33333%; }

.grid-x > .small-9 {
  width: 37.5%; }

.grid-x > .small-10 {
  width: 41.66667%; }

.grid-x > .small-11 {
  width: 45.83333%; }

.grid-x > .small-12 {
  width: 50%; }

.grid-x > .small-13 {
  width: 54.16667%; }

.grid-x > .small-14 {
  width: 58.33333%; }

.grid-x > .small-15 {
  width: 62.5%; }

.grid-x > .small-16 {
  width: 66.66667%; }

.grid-x > .small-17 {
  width: 70.83333%; }

.grid-x > .small-18 {
  width: 75%; }

.grid-x > .small-19 {
  width: 79.16667%; }

.grid-x > .small-20 {
  width: 83.33333%; }

.grid-x > .small-21 {
  width: 87.5%; }

.grid-x > .small-22 {
  width: 91.66667%; }

.grid-x > .small-23 {
  width: 95.83333%; }

.grid-x > .small-24 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 4.16667%; }
  .grid-x > .medium-2 {
    width: 8.33333%; }
  .grid-x > .medium-3 {
    width: 12.5%; }
  .grid-x > .medium-4 {
    width: 16.66667%; }
  .grid-x > .medium-5 {
    width: 20.83333%; }
  .grid-x > .medium-6 {
    width: 25%; }
  .grid-x > .medium-7 {
    width: 29.16667%; }
  .grid-x > .medium-8 {
    width: 33.33333%; }
  .grid-x > .medium-9 {
    width: 37.5%; }
  .grid-x > .medium-10 {
    width: 41.66667%; }
  .grid-x > .medium-11 {
    width: 45.83333%; }
  .grid-x > .medium-12 {
    width: 50%; }
  .grid-x > .medium-13 {
    width: 54.16667%; }
  .grid-x > .medium-14 {
    width: 58.33333%; }
  .grid-x > .medium-15 {
    width: 62.5%; }
  .grid-x > .medium-16 {
    width: 66.66667%; }
  .grid-x > .medium-17 {
    width: 70.83333%; }
  .grid-x > .medium-18 {
    width: 75%; }
  .grid-x > .medium-19 {
    width: 79.16667%; }
  .grid-x > .medium-20 {
    width: 83.33333%; }
  .grid-x > .medium-21 {
    width: 87.5%; }
  .grid-x > .medium-22 {
    width: 91.66667%; }
  .grid-x > .medium-23 {
    width: 95.83333%; }
  .grid-x > .medium-24 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-large-1 {
    width: 4.16667%; }
  .grid-x > .medium-large-2 {
    width: 8.33333%; }
  .grid-x > .medium-large-3 {
    width: 12.5%; }
  .grid-x > .medium-large-4 {
    width: 16.66667%; }
  .grid-x > .medium-large-5 {
    width: 20.83333%; }
  .grid-x > .medium-large-6 {
    width: 25%; }
  .grid-x > .medium-large-7 {
    width: 29.16667%; }
  .grid-x > .medium-large-8 {
    width: 33.33333%; }
  .grid-x > .medium-large-9 {
    width: 37.5%; }
  .grid-x > .medium-large-10 {
    width: 41.66667%; }
  .grid-x > .medium-large-11 {
    width: 45.83333%; }
  .grid-x > .medium-large-12 {
    width: 50%; }
  .grid-x > .medium-large-13 {
    width: 54.16667%; }
  .grid-x > .medium-large-14 {
    width: 58.33333%; }
  .grid-x > .medium-large-15 {
    width: 62.5%; }
  .grid-x > .medium-large-16 {
    width: 66.66667%; }
  .grid-x > .medium-large-17 {
    width: 70.83333%; }
  .grid-x > .medium-large-18 {
    width: 75%; }
  .grid-x > .medium-large-19 {
    width: 79.16667%; }
  .grid-x > .medium-large-20 {
    width: 83.33333%; }
  .grid-x > .medium-large-21 {
    width: 87.5%; }
  .grid-x > .medium-large-22 {
    width: 91.66667%; }
  .grid-x > .medium-large-23 {
    width: 95.83333%; }
  .grid-x > .medium-large-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 4.16667%; }
  .grid-x > .large-2 {
    width: 8.33333%; }
  .grid-x > .large-3 {
    width: 12.5%; }
  .grid-x > .large-4 {
    width: 16.66667%; }
  .grid-x > .large-5 {
    width: 20.83333%; }
  .grid-x > .large-6 {
    width: 25%; }
  .grid-x > .large-7 {
    width: 29.16667%; }
  .grid-x > .large-8 {
    width: 33.33333%; }
  .grid-x > .large-9 {
    width: 37.5%; }
  .grid-x > .large-10 {
    width: 41.66667%; }
  .grid-x > .large-11 {
    width: 45.83333%; }
  .grid-x > .large-12 {
    width: 50%; }
  .grid-x > .large-13 {
    width: 54.16667%; }
  .grid-x > .large-14 {
    width: 58.33333%; }
  .grid-x > .large-15 {
    width: 62.5%; }
  .grid-x > .large-16 {
    width: 66.66667%; }
  .grid-x > .large-17 {
    width: 70.83333%; }
  .grid-x > .large-18 {
    width: 75%; }
  .grid-x > .large-19 {
    width: 79.16667%; }
  .grid-x > .large-20 {
    width: 83.33333%; }
  .grid-x > .large-21 {
    width: 87.5%; }
  .grid-x > .large-22 {
    width: 91.66667%; }
  .grid-x > .large-23 {
    width: 95.83333%; }
  .grid-x > .large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .grid-x > .xlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 4.16667%; }
  .grid-x > .xlarge-2 {
    width: 8.33333%; }
  .grid-x > .xlarge-3 {
    width: 12.5%; }
  .grid-x > .xlarge-4 {
    width: 16.66667%; }
  .grid-x > .xlarge-5 {
    width: 20.83333%; }
  .grid-x > .xlarge-6 {
    width: 25%; }
  .grid-x > .xlarge-7 {
    width: 29.16667%; }
  .grid-x > .xlarge-8 {
    width: 33.33333%; }
  .grid-x > .xlarge-9 {
    width: 37.5%; }
  .grid-x > .xlarge-10 {
    width: 41.66667%; }
  .grid-x > .xlarge-11 {
    width: 45.83333%; }
  .grid-x > .xlarge-12 {
    width: 50%; }
  .grid-x > .xlarge-13 {
    width: 54.16667%; }
  .grid-x > .xlarge-14 {
    width: 58.33333%; }
  .grid-x > .xlarge-15 {
    width: 62.5%; }
  .grid-x > .xlarge-16 {
    width: 66.66667%; }
  .grid-x > .xlarge-17 {
    width: 70.83333%; }
  .grid-x > .xlarge-18 {
    width: 75%; }
  .grid-x > .xlarge-19 {
    width: 79.16667%; }
  .grid-x > .xlarge-20 {
    width: 83.33333%; }
  .grid-x > .xlarge-21 {
    width: 87.5%; }
  .grid-x > .xlarge-22 {
    width: 91.66667%; }
  .grid-x > .xlarge-23 {
    width: 95.83333%; }
  .grid-x > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .grid-x > .xxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 4.16667%; }
  .grid-x > .xxlarge-2 {
    width: 8.33333%; }
  .grid-x > .xxlarge-3 {
    width: 12.5%; }
  .grid-x > .xxlarge-4 {
    width: 16.66667%; }
  .grid-x > .xxlarge-5 {
    width: 20.83333%; }
  .grid-x > .xxlarge-6 {
    width: 25%; }
  .grid-x > .xxlarge-7 {
    width: 29.16667%; }
  .grid-x > .xxlarge-8 {
    width: 33.33333%; }
  .grid-x > .xxlarge-9 {
    width: 37.5%; }
  .grid-x > .xxlarge-10 {
    width: 41.66667%; }
  .grid-x > .xxlarge-11 {
    width: 45.83333%; }
  .grid-x > .xxlarge-12 {
    width: 50%; }
  .grid-x > .xxlarge-13 {
    width: 54.16667%; }
  .grid-x > .xxlarge-14 {
    width: 58.33333%; }
  .grid-x > .xxlarge-15 {
    width: 62.5%; }
  .grid-x > .xxlarge-16 {
    width: 66.66667%; }
  .grid-x > .xxlarge-17 {
    width: 70.83333%; }
  .grid-x > .xxlarge-18 {
    width: 75%; }
  .grid-x > .xxlarge-19 {
    width: 79.16667%; }
  .grid-x > .xxlarge-20 {
    width: 83.33333%; }
  .grid-x > .xxlarge-21 {
    width: 87.5%; }
  .grid-x > .xxlarge-22 {
    width: 91.66667%; }
  .grid-x > .xxlarge-23 {
    width: 95.83333%; }
  .grid-x > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .grid-x > .xxxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxxlarge-1 {
    width: 4.16667%; }
  .grid-x > .xxxlarge-2 {
    width: 8.33333%; }
  .grid-x > .xxxlarge-3 {
    width: 12.5%; }
  .grid-x > .xxxlarge-4 {
    width: 16.66667%; }
  .grid-x > .xxxlarge-5 {
    width: 20.83333%; }
  .grid-x > .xxxlarge-6 {
    width: 25%; }
  .grid-x > .xxxlarge-7 {
    width: 29.16667%; }
  .grid-x > .xxxlarge-8 {
    width: 33.33333%; }
  .grid-x > .xxxlarge-9 {
    width: 37.5%; }
  .grid-x > .xxxlarge-10 {
    width: 41.66667%; }
  .grid-x > .xxxlarge-11 {
    width: 45.83333%; }
  .grid-x > .xxxlarge-12 {
    width: 50%; }
  .grid-x > .xxxlarge-13 {
    width: 54.16667%; }
  .grid-x > .xxxlarge-14 {
    width: 58.33333%; }
  .grid-x > .xxxlarge-15 {
    width: 62.5%; }
  .grid-x > .xxxlarge-16 {
    width: 66.66667%; }
  .grid-x > .xxxlarge-17 {
    width: 70.83333%; }
  .grid-x > .xxxlarge-18 {
    width: 75%; }
  .grid-x > .xxxlarge-19 {
    width: 79.16667%; }
  .grid-x > .xxxlarge-20 {
    width: 83.33333%; }
  .grid-x > .xxxlarge-21 {
    width: 87.5%; }
  .grid-x > .xxxlarge-22 {
    width: 91.66667%; }
  .grid-x > .xxxlarge-23 {
    width: 95.83333%; }
  .grid-x > .xxxlarge-24 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.625rem;
      margin-right: -0.625rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.25rem);
      margin-left: 0.625rem;
      margin-right: 0.625rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(4.16667% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(20.83333% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(29.16667% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(37.5% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(45.83333% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-13 {
    width: calc(54.16667% - 1.25rem); }
  .grid-margin-x > .small-14 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-15 {
    width: calc(62.5% - 1.25rem); }
  .grid-margin-x > .small-16 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-17 {
    width: calc(70.83333% - 1.25rem); }
  .grid-margin-x > .small-18 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-19 {
    width: calc(79.16667% - 1.25rem); }
  .grid-margin-x > .small-20 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-21 {
    width: calc(87.5% - 1.25rem); }
  .grid-margin-x > .small-22 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-23 {
    width: calc(95.83333% - 1.25rem); }
  .grid-margin-x > .small-24 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .small-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .small-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .small-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .small-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .small-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .small-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .small-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .small-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .small-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .small-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .small-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .small-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .small-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .small-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .small-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .small-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .small-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .small-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .small-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .small-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .small-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .small-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .small-24 {
      width: calc(100% - 1.25rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .medium-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .medium-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .medium-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .medium-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .medium-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .medium-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .medium-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .medium-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .medium-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .medium-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .medium-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .medium-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .medium-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .medium-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .medium-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .medium-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .medium-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .medium-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .medium-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .medium-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .medium-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .medium-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .medium-24 {
      width: calc(100% - 1.25rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .medium-large-auto {
      width: auto; }
    .grid-margin-x > .medium-large-shrink {
      width: auto; }
    .grid-margin-x > .medium-large-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .medium-large-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .medium-large-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .medium-large-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .medium-large-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .medium-large-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .medium-large-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .medium-large-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .medium-large-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .medium-large-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .medium-large-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .medium-large-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .medium-large-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .medium-large-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .medium-large-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .medium-large-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .medium-large-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .medium-large-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .medium-large-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .medium-large-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .medium-large-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .medium-large-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .medium-large-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .medium-large-24 {
      width: calc(100% - 1.25rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .large-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .large-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .large-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .large-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .large-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .large-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .large-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .large-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .large-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .large-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .large-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .large-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .large-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .large-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .large-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .large-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .large-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .large-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .large-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .large-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .large-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .large-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .large-24 {
      width: calc(100% - 1.25rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-x > .xlarge-auto {
      width: auto; }
    .grid-margin-x > .xlarge-shrink {
      width: auto; }
    .grid-margin-x > .xlarge-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .xlarge-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .xlarge-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .xlarge-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .xlarge-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .xlarge-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .xlarge-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .xlarge-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .xlarge-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .xlarge-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .xlarge-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .xlarge-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .xlarge-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .xlarge-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .xlarge-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .xlarge-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .xlarge-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .xlarge-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .xlarge-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .xlarge-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .xlarge-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .xlarge-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .xlarge-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .xlarge-24 {
      width: calc(100% - 1.25rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-x > .xxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxlarge-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .xxlarge-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .xxlarge-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .xxlarge-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .xxlarge-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .xxlarge-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .xxlarge-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .xxlarge-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .xxlarge-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .xxlarge-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .xxlarge-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .xxlarge-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .xxlarge-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .xxlarge-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .xxlarge-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .xxlarge-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .xxlarge-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .xxlarge-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .xxlarge-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .xxlarge-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .xxlarge-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .xxlarge-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .xxlarge-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .xxlarge-24 {
      width: calc(100% - 1.25rem); } }
  @media screen and (min-width: 100em) {
    .grid-margin-x > .xxxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxxlarge-1 {
      width: calc(4.16667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-2 {
      width: calc(8.33333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-3 {
      width: calc(12.5% - 1.25rem); }
    .grid-margin-x > .xxxlarge-4 {
      width: calc(16.66667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-5 {
      width: calc(20.83333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-6 {
      width: calc(25% - 1.25rem); }
    .grid-margin-x > .xxxlarge-7 {
      width: calc(29.16667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-8 {
      width: calc(33.33333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-9 {
      width: calc(37.5% - 1.25rem); }
    .grid-margin-x > .xxxlarge-10 {
      width: calc(41.66667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-11 {
      width: calc(45.83333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-12 {
      width: calc(50% - 1.25rem); }
    .grid-margin-x > .xxxlarge-13 {
      width: calc(54.16667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-14 {
      width: calc(58.33333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-15 {
      width: calc(62.5% - 1.25rem); }
    .grid-margin-x > .xxxlarge-16 {
      width: calc(66.66667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-17 {
      width: calc(70.83333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-18 {
      width: calc(75% - 1.25rem); }
    .grid-margin-x > .xxxlarge-19 {
      width: calc(79.16667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-20 {
      width: calc(83.33333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-21 {
      width: calc(87.5% - 1.25rem); }
    .grid-margin-x > .xxxlarge-22 {
      width: calc(91.66667% - 1.25rem); }
    .grid-margin-x > .xxxlarge-23 {
      width: calc(95.83333% - 1.25rem); }
    .grid-margin-x > .xxxlarge-24 {
      width: calc(100% - 1.25rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.625rem;
      margin-left: -0.625rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.625rem;
      padding-left: 0.625rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

.small-up-9 > .cell {
  width: 11.11111%; }

.small-up-10 > .cell {
  width: 10%; }

.small-up-11 > .cell {
  width: 9.09091%; }

.small-up-12 > .cell {
  width: 8.33333%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; }
  .medium-up-9 > .cell {
    width: 11.11111%; }
  .medium-up-10 > .cell {
    width: 10%; }
  .medium-up-11 > .cell {
    width: 9.09091%; }
  .medium-up-12 > .cell {
    width: 8.33333%; } }

@media print, screen and (min-width: 48em) {
  .medium-large-up-1 > .cell {
    width: 100%; }
  .medium-large-up-2 > .cell {
    width: 50%; }
  .medium-large-up-3 > .cell {
    width: 33.33333%; }
  .medium-large-up-4 > .cell {
    width: 25%; }
  .medium-large-up-5 > .cell {
    width: 20%; }
  .medium-large-up-6 > .cell {
    width: 16.66667%; }
  .medium-large-up-7 > .cell {
    width: 14.28571%; }
  .medium-large-up-8 > .cell {
    width: 12.5%; }
  .medium-large-up-9 > .cell {
    width: 11.11111%; }
  .medium-large-up-10 > .cell {
    width: 10%; }
  .medium-large-up-11 > .cell {
    width: 9.09091%; }
  .medium-large-up-12 > .cell {
    width: 8.33333%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; }
  .large-up-9 > .cell {
    width: 11.11111%; }
  .large-up-10 > .cell {
    width: 10%; }
  .large-up-11 > .cell {
    width: 9.09091%; }
  .large-up-12 > .cell {
    width: 8.33333%; } }

@media screen and (min-width: 75em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; }
  .xlarge-up-9 > .cell {
    width: 11.11111%; }
  .xlarge-up-10 > .cell {
    width: 10%; }
  .xlarge-up-11 > .cell {
    width: 9.09091%; }
  .xlarge-up-12 > .cell {
    width: 8.33333%; } }

@media screen and (min-width: 90em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; }
  .xxlarge-up-9 > .cell {
    width: 11.11111%; }
  .xxlarge-up-10 > .cell {
    width: 10%; }
  .xxlarge-up-11 > .cell {
    width: 9.09091%; }
  .xxlarge-up-12 > .cell {
    width: 8.33333%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-up-1 > .cell {
    width: 100%; }
  .xxxlarge-up-2 > .cell {
    width: 50%; }
  .xxxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxxlarge-up-4 > .cell {
    width: 25%; }
  .xxxlarge-up-5 > .cell {
    width: 20%; }
  .xxxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxxlarge-up-8 > .cell {
    width: 12.5%; }
  .xxxlarge-up-9 > .cell {
    width: 11.11111%; }
  .xxxlarge-up-10 > .cell {
    width: 10%; }
  .xxxlarge-up-11 > .cell {
    width: 9.09091%; }
  .xxxlarge-up-12 > .cell {
    width: 8.33333%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

.grid-margin-x.small-up-9 > .cell {
  width: calc(11.11111% - 1.25rem); }

.grid-margin-x.small-up-10 > .cell {
  width: calc(10% - 1.25rem); }

.grid-margin-x.small-up-11 > .cell {
  width: calc(9.09091% - 1.25rem); }

.grid-margin-x.small-up-12 > .cell {
  width: calc(8.33333% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.small-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.small-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.small-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.small-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.medium-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.medium-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.medium-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.medium-large-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.medium-large-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.medium-large-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.medium-large-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.medium-large-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.medium-large-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.medium-large-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.medium-large-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-large-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.medium-large-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.medium-large-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.medium-large-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.large-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.large-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.large-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.large-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.xlarge-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.xlarge-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.xlarge-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.xlarge-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

@media screen and (min-width: 90em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.xxlarge-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.xxlarge-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.xxlarge-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.xxlarge-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

@media screen and (min-width: 100em) {
  .grid-margin-x.xxxlarge-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-9 > .cell {
    width: calc(11.11111% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-10 > .cell {
    width: calc(10% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-11 > .cell {
    width: calc(9.09091% - 1.25rem); }
  .grid-margin-x.xxxlarge-up-12 > .cell {
    width: calc(8.33333% - 1.25rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 4.16667%; }
  .small-margin-collapse > .small-2 {
    width: 8.33333%; }
  .small-margin-collapse > .small-3 {
    width: 12.5%; }
  .small-margin-collapse > .small-4 {
    width: 16.66667%; }
  .small-margin-collapse > .small-5 {
    width: 20.83333%; }
  .small-margin-collapse > .small-6 {
    width: 25%; }
  .small-margin-collapse > .small-7 {
    width: 29.16667%; }
  .small-margin-collapse > .small-8 {
    width: 33.33333%; }
  .small-margin-collapse > .small-9 {
    width: 37.5%; }
  .small-margin-collapse > .small-10 {
    width: 41.66667%; }
  .small-margin-collapse > .small-11 {
    width: 45.83333%; }
  .small-margin-collapse > .small-12 {
    width: 50%; }
  .small-margin-collapse > .small-13 {
    width: 54.16667%; }
  .small-margin-collapse > .small-14 {
    width: 58.33333%; }
  .small-margin-collapse > .small-15 {
    width: 62.5%; }
  .small-margin-collapse > .small-16 {
    width: 66.66667%; }
  .small-margin-collapse > .small-17 {
    width: 70.83333%; }
  .small-margin-collapse > .small-18 {
    width: 75%; }
  .small-margin-collapse > .small-19 {
    width: 79.16667%; }
  .small-margin-collapse > .small-20 {
    width: 83.33333%; }
  .small-margin-collapse > .small-21 {
    width: 87.5%; }
  .small-margin-collapse > .small-22 {
    width: 91.66667%; }
  .small-margin-collapse > .small-23 {
    width: 95.83333%; }
  .small-margin-collapse > .small-24 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 4.16667%; }
    .small-margin-collapse > .medium-2 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-3 {
      width: 12.5%; }
    .small-margin-collapse > .medium-4 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-5 {
      width: 20.83333%; }
    .small-margin-collapse > .medium-6 {
      width: 25%; }
    .small-margin-collapse > .medium-7 {
      width: 29.16667%; }
    .small-margin-collapse > .medium-8 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-9 {
      width: 37.5%; }
    .small-margin-collapse > .medium-10 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-11 {
      width: 45.83333%; }
    .small-margin-collapse > .medium-12 {
      width: 50%; }
    .small-margin-collapse > .medium-13 {
      width: 54.16667%; }
    .small-margin-collapse > .medium-14 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-15 {
      width: 62.5%; }
    .small-margin-collapse > .medium-16 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-17 {
      width: 70.83333%; }
    .small-margin-collapse > .medium-18 {
      width: 75%; }
    .small-margin-collapse > .medium-19 {
      width: 79.16667%; }
    .small-margin-collapse > .medium-20 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-21 {
      width: 87.5%; }
    .small-margin-collapse > .medium-22 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-23 {
      width: 95.83333%; }
    .small-margin-collapse > .medium-24 {
      width: 100%; } }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .medium-large-1 {
      width: 4.16667%; }
    .small-margin-collapse > .medium-large-2 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-large-3 {
      width: 12.5%; }
    .small-margin-collapse > .medium-large-4 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-large-5 {
      width: 20.83333%; }
    .small-margin-collapse > .medium-large-6 {
      width: 25%; }
    .small-margin-collapse > .medium-large-7 {
      width: 29.16667%; }
    .small-margin-collapse > .medium-large-8 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-large-9 {
      width: 37.5%; }
    .small-margin-collapse > .medium-large-10 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-large-11 {
      width: 45.83333%; }
    .small-margin-collapse > .medium-large-12 {
      width: 50%; }
    .small-margin-collapse > .medium-large-13 {
      width: 54.16667%; }
    .small-margin-collapse > .medium-large-14 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-large-15 {
      width: 62.5%; }
    .small-margin-collapse > .medium-large-16 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-large-17 {
      width: 70.83333%; }
    .small-margin-collapse > .medium-large-18 {
      width: 75%; }
    .small-margin-collapse > .medium-large-19 {
      width: 79.16667%; }
    .small-margin-collapse > .medium-large-20 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-large-21 {
      width: 87.5%; }
    .small-margin-collapse > .medium-large-22 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-large-23 {
      width: 95.83333%; }
    .small-margin-collapse > .medium-large-24 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 4.16667%; }
    .small-margin-collapse > .large-2 {
      width: 8.33333%; }
    .small-margin-collapse > .large-3 {
      width: 12.5%; }
    .small-margin-collapse > .large-4 {
      width: 16.66667%; }
    .small-margin-collapse > .large-5 {
      width: 20.83333%; }
    .small-margin-collapse > .large-6 {
      width: 25%; }
    .small-margin-collapse > .large-7 {
      width: 29.16667%; }
    .small-margin-collapse > .large-8 {
      width: 33.33333%; }
    .small-margin-collapse > .large-9 {
      width: 37.5%; }
    .small-margin-collapse > .large-10 {
      width: 41.66667%; }
    .small-margin-collapse > .large-11 {
      width: 45.83333%; }
    .small-margin-collapse > .large-12 {
      width: 50%; }
    .small-margin-collapse > .large-13 {
      width: 54.16667%; }
    .small-margin-collapse > .large-14 {
      width: 58.33333%; }
    .small-margin-collapse > .large-15 {
      width: 62.5%; }
    .small-margin-collapse > .large-16 {
      width: 66.66667%; }
    .small-margin-collapse > .large-17 {
      width: 70.83333%; }
    .small-margin-collapse > .large-18 {
      width: 75%; }
    .small-margin-collapse > .large-19 {
      width: 79.16667%; }
    .small-margin-collapse > .large-20 {
      width: 83.33333%; }
    .small-margin-collapse > .large-21 {
      width: 87.5%; }
    .small-margin-collapse > .large-22 {
      width: 91.66667%; }
    .small-margin-collapse > .large-23 {
      width: 95.83333%; }
    .small-margin-collapse > .large-24 {
      width: 100%; } }
  @media screen and (min-width: 75em) {
    .small-margin-collapse > .xlarge-1 {
      width: 4.16667%; }
    .small-margin-collapse > .xlarge-2 {
      width: 8.33333%; }
    .small-margin-collapse > .xlarge-3 {
      width: 12.5%; }
    .small-margin-collapse > .xlarge-4 {
      width: 16.66667%; }
    .small-margin-collapse > .xlarge-5 {
      width: 20.83333%; }
    .small-margin-collapse > .xlarge-6 {
      width: 25%; }
    .small-margin-collapse > .xlarge-7 {
      width: 29.16667%; }
    .small-margin-collapse > .xlarge-8 {
      width: 33.33333%; }
    .small-margin-collapse > .xlarge-9 {
      width: 37.5%; }
    .small-margin-collapse > .xlarge-10 {
      width: 41.66667%; }
    .small-margin-collapse > .xlarge-11 {
      width: 45.83333%; }
    .small-margin-collapse > .xlarge-12 {
      width: 50%; }
    .small-margin-collapse > .xlarge-13 {
      width: 54.16667%; }
    .small-margin-collapse > .xlarge-14 {
      width: 58.33333%; }
    .small-margin-collapse > .xlarge-15 {
      width: 62.5%; }
    .small-margin-collapse > .xlarge-16 {
      width: 66.66667%; }
    .small-margin-collapse > .xlarge-17 {
      width: 70.83333%; }
    .small-margin-collapse > .xlarge-18 {
      width: 75%; }
    .small-margin-collapse > .xlarge-19 {
      width: 79.16667%; }
    .small-margin-collapse > .xlarge-20 {
      width: 83.33333%; }
    .small-margin-collapse > .xlarge-21 {
      width: 87.5%; }
    .small-margin-collapse > .xlarge-22 {
      width: 91.66667%; }
    .small-margin-collapse > .xlarge-23 {
      width: 95.83333%; }
    .small-margin-collapse > .xlarge-24 {
      width: 100%; } }
  @media screen and (min-width: 90em) {
    .small-margin-collapse > .xxlarge-1 {
      width: 4.16667%; }
    .small-margin-collapse > .xxlarge-2 {
      width: 8.33333%; }
    .small-margin-collapse > .xxlarge-3 {
      width: 12.5%; }
    .small-margin-collapse > .xxlarge-4 {
      width: 16.66667%; }
    .small-margin-collapse > .xxlarge-5 {
      width: 20.83333%; }
    .small-margin-collapse > .xxlarge-6 {
      width: 25%; }
    .small-margin-collapse > .xxlarge-7 {
      width: 29.16667%; }
    .small-margin-collapse > .xxlarge-8 {
      width: 33.33333%; }
    .small-margin-collapse > .xxlarge-9 {
      width: 37.5%; }
    .small-margin-collapse > .xxlarge-10 {
      width: 41.66667%; }
    .small-margin-collapse > .xxlarge-11 {
      width: 45.83333%; }
    .small-margin-collapse > .xxlarge-12 {
      width: 50%; }
    .small-margin-collapse > .xxlarge-13 {
      width: 54.16667%; }
    .small-margin-collapse > .xxlarge-14 {
      width: 58.33333%; }
    .small-margin-collapse > .xxlarge-15 {
      width: 62.5%; }
    .small-margin-collapse > .xxlarge-16 {
      width: 66.66667%; }
    .small-margin-collapse > .xxlarge-17 {
      width: 70.83333%; }
    .small-margin-collapse > .xxlarge-18 {
      width: 75%; }
    .small-margin-collapse > .xxlarge-19 {
      width: 79.16667%; }
    .small-margin-collapse > .xxlarge-20 {
      width: 83.33333%; }
    .small-margin-collapse > .xxlarge-21 {
      width: 87.5%; }
    .small-margin-collapse > .xxlarge-22 {
      width: 91.66667%; }
    .small-margin-collapse > .xxlarge-23 {
      width: 95.83333%; }
    .small-margin-collapse > .xxlarge-24 {
      width: 100%; } }
  @media screen and (min-width: 100em) {
    .small-margin-collapse > .xxxlarge-1 {
      width: 4.16667%; }
    .small-margin-collapse > .xxxlarge-2 {
      width: 8.33333%; }
    .small-margin-collapse > .xxxlarge-3 {
      width: 12.5%; }
    .small-margin-collapse > .xxxlarge-4 {
      width: 16.66667%; }
    .small-margin-collapse > .xxxlarge-5 {
      width: 20.83333%; }
    .small-margin-collapse > .xxxlarge-6 {
      width: 25%; }
    .small-margin-collapse > .xxxlarge-7 {
      width: 29.16667%; }
    .small-margin-collapse > .xxxlarge-8 {
      width: 33.33333%; }
    .small-margin-collapse > .xxxlarge-9 {
      width: 37.5%; }
    .small-margin-collapse > .xxxlarge-10 {
      width: 41.66667%; }
    .small-margin-collapse > .xxxlarge-11 {
      width: 45.83333%; }
    .small-margin-collapse > .xxxlarge-12 {
      width: 50%; }
    .small-margin-collapse > .xxxlarge-13 {
      width: 54.16667%; }
    .small-margin-collapse > .xxxlarge-14 {
      width: 58.33333%; }
    .small-margin-collapse > .xxxlarge-15 {
      width: 62.5%; }
    .small-margin-collapse > .xxxlarge-16 {
      width: 66.66667%; }
    .small-margin-collapse > .xxxlarge-17 {
      width: 70.83333%; }
    .small-margin-collapse > .xxxlarge-18 {
      width: 75%; }
    .small-margin-collapse > .xxxlarge-19 {
      width: 79.16667%; }
    .small-margin-collapse > .xxxlarge-20 {
      width: 83.33333%; }
    .small-margin-collapse > .xxxlarge-21 {
      width: 87.5%; }
    .small-margin-collapse > .xxxlarge-22 {
      width: 91.66667%; }
    .small-margin-collapse > .xxxlarge-23 {
      width: 95.83333%; }
    .small-margin-collapse > .xxxlarge-24 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .small-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-3 {
    width: 12.5%; }
  .medium-margin-collapse > .small-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .small-6 {
    width: 25%; }
  .medium-margin-collapse > .small-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .small-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-9 {
    width: 37.5%; }
  .medium-margin-collapse > .small-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .small-12 {
    width: 50%; }
  .medium-margin-collapse > .small-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .small-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-15 {
    width: 62.5%; }
  .medium-margin-collapse > .small-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .small-18 {
    width: 75%; }
  .medium-margin-collapse > .small-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .small-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-21 {
    width: 87.5%; }
  .medium-margin-collapse > .small-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .small-24 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-3 {
    width: 12.5%; }
  .medium-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .medium-6 {
    width: 25%; }
  .medium-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-9 {
    width: 37.5%; }
  .medium-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .medium-12 {
    width: 50%; }
  .medium-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-15 {
    width: 62.5%; }
  .medium-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .medium-18 {
    width: 75%; }
  .medium-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-21 {
    width: 87.5%; }
  .medium-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .medium-24 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .medium-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .medium-large-6 {
    width: 25%; }
  .medium-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .medium-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .medium-large-12 {
    width: 50%; }
  .medium-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .medium-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .medium-large-18 {
    width: 75%; }
  .medium-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .medium-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .large-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-3 {
    width: 12.5%; }
  .medium-margin-collapse > .large-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .large-6 {
    width: 25%; }
  .medium-margin-collapse > .large-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .large-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-9 {
    width: 37.5%; }
  .medium-margin-collapse > .large-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .large-12 {
    width: 50%; }
  .medium-margin-collapse > .large-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .large-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-15 {
    width: 62.5%; }
  .medium-margin-collapse > .large-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .large-18 {
    width: 75%; }
  .medium-margin-collapse > .large-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .large-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-21 {
    width: 87.5%; }
  .medium-margin-collapse > .large-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .medium-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .medium-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .xlarge-6 {
    width: 25%; }
  .medium-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .medium-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .xlarge-12 {
    width: 50%; }
  .medium-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .medium-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .xlarge-18 {
    width: 75%; }
  .medium-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .medium-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .medium-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .medium-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .medium-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .medium-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .medium-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .medium-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .medium-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .medium-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .medium-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .medium-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .medium-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .medium-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .medium-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .medium-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .medium-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .medium-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .medium-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .medium-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .medium-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .medium-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .medium-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .medium-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .medium-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .medium-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 48em) {
  .medium-large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .medium-large-margin-collapse > .small-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .small-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .small-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .small-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .small-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .small-6 {
    width: 25%; }
  .medium-large-margin-collapse > .small-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .small-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .small-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .small-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .small-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .small-12 {
    width: 50%; }
  .medium-large-margin-collapse > .small-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .small-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .small-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .small-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .small-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .small-18 {
    width: 75%; }
  .medium-large-margin-collapse > .small-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .small-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .small-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .small-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .small-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .small-24 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-large-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .medium-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .medium-6 {
    width: 25%; }
  .medium-large-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .medium-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .medium-12 {
    width: 50%; }
  .medium-large-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .medium-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .medium-18 {
    width: 75%; }
  .medium-large-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .medium-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .medium-24 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-large-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .medium-large-6 {
    width: 25%; }
  .medium-large-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .medium-large-12 {
    width: 50%; }
  .medium-large-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .medium-large-18 {
    width: 75%; }
  .medium-large-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-large-margin-collapse > .large-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .large-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .large-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .large-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .large-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .large-6 {
    width: 25%; }
  .medium-large-margin-collapse > .large-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .large-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .large-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .large-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .large-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .large-12 {
    width: 50%; }
  .medium-large-margin-collapse > .large-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .large-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .large-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .large-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .large-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .large-18 {
    width: 75%; }
  .medium-large-margin-collapse > .large-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .large-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .large-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .large-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .large-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .medium-large-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .xlarge-6 {
    width: 25%; }
  .medium-large-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .xlarge-12 {
    width: 50%; }
  .medium-large-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .xlarge-18 {
    width: 75%; }
  .medium-large-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .medium-large-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .medium-large-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .medium-large-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .medium-large-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .medium-large-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .medium-large-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .medium-large-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .medium-large-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .medium-large-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .medium-large-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .medium-large-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .medium-large-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .medium-large-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .medium-large-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .medium-large-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .medium-large-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .medium-large-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .medium-large-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .medium-large-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .medium-large-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .medium-large-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .medium-large-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .medium-large-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .medium-large-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .medium-large-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .medium-large-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .medium-large-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .medium-large-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 4.16667%; }
  .large-margin-collapse > .small-2 {
    width: 8.33333%; }
  .large-margin-collapse > .small-3 {
    width: 12.5%; }
  .large-margin-collapse > .small-4 {
    width: 16.66667%; }
  .large-margin-collapse > .small-5 {
    width: 20.83333%; }
  .large-margin-collapse > .small-6 {
    width: 25%; }
  .large-margin-collapse > .small-7 {
    width: 29.16667%; }
  .large-margin-collapse > .small-8 {
    width: 33.33333%; }
  .large-margin-collapse > .small-9 {
    width: 37.5%; }
  .large-margin-collapse > .small-10 {
    width: 41.66667%; }
  .large-margin-collapse > .small-11 {
    width: 45.83333%; }
  .large-margin-collapse > .small-12 {
    width: 50%; }
  .large-margin-collapse > .small-13 {
    width: 54.16667%; }
  .large-margin-collapse > .small-14 {
    width: 58.33333%; }
  .large-margin-collapse > .small-15 {
    width: 62.5%; }
  .large-margin-collapse > .small-16 {
    width: 66.66667%; }
  .large-margin-collapse > .small-17 {
    width: 70.83333%; }
  .large-margin-collapse > .small-18 {
    width: 75%; }
  .large-margin-collapse > .small-19 {
    width: 79.16667%; }
  .large-margin-collapse > .small-20 {
    width: 83.33333%; }
  .large-margin-collapse > .small-21 {
    width: 87.5%; }
  .large-margin-collapse > .small-22 {
    width: 91.66667%; }
  .large-margin-collapse > .small-23 {
    width: 95.83333%; }
  .large-margin-collapse > .small-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .large-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-3 {
    width: 12.5%; }
  .large-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .large-margin-collapse > .medium-6 {
    width: 25%; }
  .large-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .large-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-9 {
    width: 37.5%; }
  .large-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .large-margin-collapse > .medium-12 {
    width: 50%; }
  .large-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .large-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-15 {
    width: 62.5%; }
  .large-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .large-margin-collapse > .medium-18 {
    width: 75%; }
  .large-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .large-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-21 {
    width: 87.5%; }
  .large-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .large-margin-collapse > .medium-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .large-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .large-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .large-margin-collapse > .medium-large-6 {
    width: 25%; }
  .large-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .large-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .large-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .large-margin-collapse > .medium-large-12 {
    width: 50%; }
  .large-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .large-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .large-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .large-margin-collapse > .medium-large-18 {
    width: 75%; }
  .large-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .large-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .large-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .large-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 4.16667%; }
  .large-margin-collapse > .large-2 {
    width: 8.33333%; }
  .large-margin-collapse > .large-3 {
    width: 12.5%; }
  .large-margin-collapse > .large-4 {
    width: 16.66667%; }
  .large-margin-collapse > .large-5 {
    width: 20.83333%; }
  .large-margin-collapse > .large-6 {
    width: 25%; }
  .large-margin-collapse > .large-7 {
    width: 29.16667%; }
  .large-margin-collapse > .large-8 {
    width: 33.33333%; }
  .large-margin-collapse > .large-9 {
    width: 37.5%; }
  .large-margin-collapse > .large-10 {
    width: 41.66667%; }
  .large-margin-collapse > .large-11 {
    width: 45.83333%; }
  .large-margin-collapse > .large-12 {
    width: 50%; }
  .large-margin-collapse > .large-13 {
    width: 54.16667%; }
  .large-margin-collapse > .large-14 {
    width: 58.33333%; }
  .large-margin-collapse > .large-15 {
    width: 62.5%; }
  .large-margin-collapse > .large-16 {
    width: 66.66667%; }
  .large-margin-collapse > .large-17 {
    width: 70.83333%; }
  .large-margin-collapse > .large-18 {
    width: 75%; }
  .large-margin-collapse > .large-19 {
    width: 79.16667%; }
  .large-margin-collapse > .large-20 {
    width: 83.33333%; }
  .large-margin-collapse > .large-21 {
    width: 87.5%; }
  .large-margin-collapse > .large-22 {
    width: 91.66667%; }
  .large-margin-collapse > .large-23 {
    width: 95.83333%; }
  .large-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .large-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .large-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .large-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .large-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .large-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .large-margin-collapse > .xlarge-6 {
    width: 25%; }
  .large-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .large-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .large-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .large-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .large-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .large-margin-collapse > .xlarge-12 {
    width: 50%; }
  .large-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .large-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .large-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .large-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .large-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .large-margin-collapse > .xlarge-18 {
    width: 75%; }
  .large-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .large-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .large-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .large-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .large-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .large-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .large-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .large-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .large-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .large-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .large-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .large-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .large-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .large-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .large-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .large-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .large-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .large-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .large-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .large-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .large-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .large-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .large-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .large-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .large-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .large-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .large-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .large-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .large-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .large-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .large-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .large-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .large-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .large-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .large-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .large-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .large-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .large-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .large-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .large-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .large-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .large-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .large-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .large-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .large-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .large-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .large-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .large-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .large-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .large-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .large-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .large-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .large-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .large-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .small-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .small-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .small-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .small-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .small-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .small-6 {
    width: 25%; }
  .xlarge-margin-collapse > .small-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .small-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .small-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .small-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .small-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .small-12 {
    width: 50%; }
  .xlarge-margin-collapse > .small-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .small-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .small-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .small-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .small-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .small-18 {
    width: 75%; }
  .xlarge-margin-collapse > .small-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .small-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .small-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .small-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .small-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .small-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .medium-6 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .medium-12 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .medium-18 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .medium-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .medium-large-6 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .medium-large-12 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .medium-large-18 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .large-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .large-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .large-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .large-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .large-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .large-6 {
    width: 25%; }
  .xlarge-margin-collapse > .large-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .large-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .large-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .large-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .large-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .large-12 {
    width: 50%; }
  .xlarge-margin-collapse > .large-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .large-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .large-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .large-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .large-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .large-18 {
    width: 75%; }
  .xlarge-margin-collapse > .large-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .large-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .large-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .large-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .large-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .xlarge-6 {
    width: 25%; }
  .xlarge-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .xlarge-12 {
    width: 50%; }
  .xlarge-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .xlarge-18 {
    width: 75%; }
  .xlarge-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xlarge-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .xlarge-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .xlarge-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .xlarge-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xlarge-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .xlarge-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .xlarge-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .xlarge-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .xlarge-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .xlarge-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .xlarge-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .xlarge-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .xlarge-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .xlarge-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .xlarge-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .xlarge-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .xlarge-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .xlarge-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .xlarge-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .xlarge-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .small-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .small-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .small-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .small-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .small-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .small-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .small-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .small-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .small-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .small-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .small-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .small-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .small-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .small-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .small-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .small-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .small-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .small-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .small-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .small-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .small-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .small-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .small-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .small-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .medium-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .medium-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .medium-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .medium-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .medium-large-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .medium-large-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .medium-large-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .large-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .large-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .large-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .large-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .large-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .large-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .large-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .large-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .large-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .large-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .large-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .large-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .large-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .large-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .large-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .large-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .large-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .large-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .large-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .large-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .large-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .large-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .large-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .xlarge-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .xlarge-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .xlarge-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxlarge-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .xxlarge-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .xxlarge-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .xxlarge-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .xxlarge-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .xxlarge-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .xxlarge-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .xxlarge-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .xxlarge-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .xxlarge-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .xxlarge-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .xxlarge-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .xxlarge-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .small-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .small-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .small-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .small-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .small-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .small-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .small-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .small-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .small-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .small-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .small-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .small-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .small-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .small-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .small-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .small-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .small-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .small-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .small-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .small-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .small-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .small-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .small-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .small-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .medium-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .medium-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .medium-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .medium-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .medium-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .medium-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .medium-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .medium-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .medium-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .medium-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .medium-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .medium-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .medium-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .medium-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .medium-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .medium-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .medium-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .medium-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .medium-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .medium-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .medium-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .medium-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .medium-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .medium-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .medium-large-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .medium-large-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .medium-large-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .medium-large-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .medium-large-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .medium-large-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .medium-large-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .medium-large-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .medium-large-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .medium-large-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .medium-large-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .medium-large-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .medium-large-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .medium-large-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .medium-large-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .medium-large-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .medium-large-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .medium-large-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .medium-large-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .medium-large-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .medium-large-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .medium-large-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .medium-large-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .medium-large-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .large-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .large-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .large-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .large-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .large-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .large-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .large-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .large-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .large-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .large-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .large-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .large-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .large-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .large-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .large-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .large-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .large-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .large-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .large-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .large-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .large-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .large-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .large-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .large-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .xlarge-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .xlarge-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .xlarge-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .xlarge-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .xlarge-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .xlarge-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .xlarge-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .xlarge-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .xlarge-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .xlarge-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .xlarge-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .xlarge-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .xlarge-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .xlarge-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .xlarge-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .xlarge-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .xlarge-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .xlarge-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .xlarge-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .xlarge-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .xlarge-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .xlarge-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .xlarge-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .xlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .xxlarge-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .xxlarge-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .xxlarge-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .xxlarge-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .xxlarge-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .xxlarge-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .xxlarge-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .xxlarge-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .xxlarge-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .xxlarge-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .xxlarge-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .xxlarge-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .xxlarge-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .xxlarge-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .xxlarge-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .xxlarge-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .xxlarge-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .xxlarge-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .xxlarge-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .xxlarge-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .xxlarge-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .xxlarge-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .xxlarge-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .xxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-margin-collapse > .xxxlarge-1 {
    width: 4.16667%; }
  .xxxlarge-margin-collapse > .xxxlarge-2 {
    width: 8.33333%; }
  .xxxlarge-margin-collapse > .xxxlarge-3 {
    width: 12.5%; }
  .xxxlarge-margin-collapse > .xxxlarge-4 {
    width: 16.66667%; }
  .xxxlarge-margin-collapse > .xxxlarge-5 {
    width: 20.83333%; }
  .xxxlarge-margin-collapse > .xxxlarge-6 {
    width: 25%; }
  .xxxlarge-margin-collapse > .xxxlarge-7 {
    width: 29.16667%; }
  .xxxlarge-margin-collapse > .xxxlarge-8 {
    width: 33.33333%; }
  .xxxlarge-margin-collapse > .xxxlarge-9 {
    width: 37.5%; }
  .xxxlarge-margin-collapse > .xxxlarge-10 {
    width: 41.66667%; }
  .xxxlarge-margin-collapse > .xxxlarge-11 {
    width: 45.83333%; }
  .xxxlarge-margin-collapse > .xxxlarge-12 {
    width: 50%; }
  .xxxlarge-margin-collapse > .xxxlarge-13 {
    width: 54.16667%; }
  .xxxlarge-margin-collapse > .xxxlarge-14 {
    width: 58.33333%; }
  .xxxlarge-margin-collapse > .xxxlarge-15 {
    width: 62.5%; }
  .xxxlarge-margin-collapse > .xxxlarge-16 {
    width: 66.66667%; }
  .xxxlarge-margin-collapse > .xxxlarge-17 {
    width: 70.83333%; }
  .xxxlarge-margin-collapse > .xxxlarge-18 {
    width: 75%; }
  .xxxlarge-margin-collapse > .xxxlarge-19 {
    width: 79.16667%; }
  .xxxlarge-margin-collapse > .xxxlarge-20 {
    width: 83.33333%; }
  .xxxlarge-margin-collapse > .xxxlarge-21 {
    width: 87.5%; }
  .xxxlarge-margin-collapse > .xxxlarge-22 {
    width: 91.66667%; }
  .xxxlarge-margin-collapse > .xxxlarge-23 {
    width: 95.83333%; }
  .xxxlarge-margin-collapse > .xxxlarge-24 {
    width: 100%; } }

@media screen and (min-width: 100em) {
  .xxxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 4.16667%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(4.16667% + 0.625rem); }

.small-offset-2 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-3 {
  margin-left: 12.5%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(12.5% + 0.625rem); }

.small-offset-4 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-5 {
  margin-left: 20.83333%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(20.83333% + 0.625rem); }

.small-offset-6 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-7 {
  margin-left: 29.16667%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(29.16667% + 0.625rem); }

.small-offset-8 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-9 {
  margin-left: 37.5%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(37.5% + 0.625rem); }

.small-offset-10 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-11 {
  margin-left: 45.83333%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(45.83333% + 0.625rem); }

.small-offset-12 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-12 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-13 {
  margin-left: 54.16667%; }

.grid-margin-x > .small-offset-13 {
  margin-left: calc(54.16667% + 0.625rem); }

.small-offset-14 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-14 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-15 {
  margin-left: 62.5%; }

.grid-margin-x > .small-offset-15 {
  margin-left: calc(62.5% + 0.625rem); }

.small-offset-16 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-16 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-17 {
  margin-left: 70.83333%; }

.grid-margin-x > .small-offset-17 {
  margin-left: calc(70.83333% + 0.625rem); }

.small-offset-18 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-18 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-19 {
  margin-left: 79.16667%; }

.grid-margin-x > .small-offset-19 {
  margin-left: calc(79.16667% + 0.625rem); }

.small-offset-20 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-20 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-21 {
  margin-left: 87.5%; }

.grid-margin-x > .small-offset-21 {
  margin-left: calc(87.5% + 0.625rem); }

.small-offset-22 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-22 {
  margin-left: calc(91.66667% + 0.625rem); }

.small-offset-23 {
  margin-left: 95.83333%; }

.grid-margin-x > .small-offset-23 {
  margin-left: calc(95.83333% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .medium-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .medium-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .medium-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .medium-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .medium-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .medium-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .medium-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .medium-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .medium-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .medium-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .medium-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .medium-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .medium-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .medium-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .medium-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .medium-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .medium-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .medium-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .medium-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .medium-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .medium-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .medium-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .medium-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .medium-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .medium-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .medium-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .medium-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .medium-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .medium-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

@media print, screen and (min-width: 48em) {
  .medium-large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-large-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .medium-large-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .medium-large-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .medium-large-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-large-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .medium-large-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .medium-large-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .medium-large-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-large-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .medium-large-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .medium-large-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .medium-large-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .medium-large-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .medium-large-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .medium-large-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .medium-large-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-large-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .medium-large-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .medium-large-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .medium-large-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-large-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .medium-large-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .medium-large-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .medium-large-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .medium-large-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .medium-large-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .medium-large-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .medium-large-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-large-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .medium-large-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .medium-large-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .medium-large-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-large-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .medium-large-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .medium-large-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .medium-large-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .medium-large-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .medium-large-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .medium-large-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .medium-large-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-large-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .medium-large-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .medium-large-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .medium-large-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-large-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .medium-large-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .medium-large-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .large-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .large-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .large-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .large-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .large-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .large-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .large-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .large-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .large-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .large-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .large-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .large-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .large-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .large-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .large-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .large-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .large-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .large-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .large-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .large-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .large-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .large-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .large-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .large-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .large-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .large-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .large-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .large-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .large-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

@media screen and (min-width: 75em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .xlarge-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .xlarge-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .xlarge-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .xlarge-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .xlarge-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .xlarge-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .xlarge-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .xlarge-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .xlarge-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .xlarge-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .xlarge-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .xlarge-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .xlarge-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .xlarge-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .xlarge-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .xlarge-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .xlarge-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .xlarge-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .xlarge-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .xlarge-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .xlarge-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .xlarge-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .xlarge-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .xlarge-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .xlarge-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .xlarge-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .xlarge-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .xlarge-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .xlarge-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

@media screen and (min-width: 90em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .xxlarge-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .xxlarge-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .xxlarge-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .xxlarge-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .xxlarge-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .xxlarge-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .xxlarge-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .xxlarge-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .xxlarge-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .xxlarge-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .xxlarge-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .xxlarge-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .xxlarge-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .xxlarge-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .xxlarge-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .xxlarge-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .xxlarge-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .xxlarge-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .xxlarge-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .xxlarge-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .xxlarge-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .xxlarge-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .xxlarge-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .xxlarge-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .xxlarge-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .xxlarge-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .xxlarge-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .xxlarge-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .xxlarge-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

@media screen and (min-width: 100em) {
  .xxxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxxlarge-offset-0 {
    margin-left: calc(0% + 0.625rem); }
  .xxxlarge-offset-1 {
    margin-left: 4.16667%; }
  .grid-margin-x > .xxxlarge-offset-1 {
    margin-left: calc(4.16667% + 0.625rem); }
  .xxxlarge-offset-2 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxxlarge-offset-2 {
    margin-left: calc(8.33333% + 0.625rem); }
  .xxxlarge-offset-3 {
    margin-left: 12.5%; }
  .grid-margin-x > .xxxlarge-offset-3 {
    margin-left: calc(12.5% + 0.625rem); }
  .xxxlarge-offset-4 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxxlarge-offset-4 {
    margin-left: calc(16.66667% + 0.625rem); }
  .xxxlarge-offset-5 {
    margin-left: 20.83333%; }
  .grid-margin-x > .xxxlarge-offset-5 {
    margin-left: calc(20.83333% + 0.625rem); }
  .xxxlarge-offset-6 {
    margin-left: 25%; }
  .grid-margin-x > .xxxlarge-offset-6 {
    margin-left: calc(25% + 0.625rem); }
  .xxxlarge-offset-7 {
    margin-left: 29.16667%; }
  .grid-margin-x > .xxxlarge-offset-7 {
    margin-left: calc(29.16667% + 0.625rem); }
  .xxxlarge-offset-8 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxxlarge-offset-8 {
    margin-left: calc(33.33333% + 0.625rem); }
  .xxxlarge-offset-9 {
    margin-left: 37.5%; }
  .grid-margin-x > .xxxlarge-offset-9 {
    margin-left: calc(37.5% + 0.625rem); }
  .xxxlarge-offset-10 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxxlarge-offset-10 {
    margin-left: calc(41.66667% + 0.625rem); }
  .xxxlarge-offset-11 {
    margin-left: 45.83333%; }
  .grid-margin-x > .xxxlarge-offset-11 {
    margin-left: calc(45.83333% + 0.625rem); }
  .xxxlarge-offset-12 {
    margin-left: 50%; }
  .grid-margin-x > .xxxlarge-offset-12 {
    margin-left: calc(50% + 0.625rem); }
  .xxxlarge-offset-13 {
    margin-left: 54.16667%; }
  .grid-margin-x > .xxxlarge-offset-13 {
    margin-left: calc(54.16667% + 0.625rem); }
  .xxxlarge-offset-14 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxxlarge-offset-14 {
    margin-left: calc(58.33333% + 0.625rem); }
  .xxxlarge-offset-15 {
    margin-left: 62.5%; }
  .grid-margin-x > .xxxlarge-offset-15 {
    margin-left: calc(62.5% + 0.625rem); }
  .xxxlarge-offset-16 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxxlarge-offset-16 {
    margin-left: calc(66.66667% + 0.625rem); }
  .xxxlarge-offset-17 {
    margin-left: 70.83333%; }
  .grid-margin-x > .xxxlarge-offset-17 {
    margin-left: calc(70.83333% + 0.625rem); }
  .xxxlarge-offset-18 {
    margin-left: 75%; }
  .grid-margin-x > .xxxlarge-offset-18 {
    margin-left: calc(75% + 0.625rem); }
  .xxxlarge-offset-19 {
    margin-left: 79.16667%; }
  .grid-margin-x > .xxxlarge-offset-19 {
    margin-left: calc(79.16667% + 0.625rem); }
  .xxxlarge-offset-20 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxxlarge-offset-20 {
    margin-left: calc(83.33333% + 0.625rem); }
  .xxxlarge-offset-21 {
    margin-left: 87.5%; }
  .grid-margin-x > .xxxlarge-offset-21 {
    margin-left: calc(87.5% + 0.625rem); }
  .xxxlarge-offset-22 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxxlarge-offset-22 {
    margin-left: calc(91.66667% + 0.625rem); }
  .xxxlarge-offset-23 {
    margin-left: 95.83333%; }
  .grid-margin-x > .xxxlarge-offset-23 {
    margin-left: calc(95.83333% + 0.625rem); } }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 47.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium-large {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-medium-large {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-medium-large-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-medium-large-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) and (max-width: 99.9375em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em), screen and (min-width: 100em) {
  .show-for-xxlarge-only {
    display: none !important; } }

@media screen and (min-width: 100em) {
  .hide-for-xxxlarge {
    display: none !important; } }

@media screen and (max-width: 99.9375em) {
  .show-for-xxxlarge {
    display: none !important; } }

@media screen and (min-width: 100em) {
  .hide-for-xxxlarge-only {
    display: none !important; } }

@media screen and (max-width: 99.9375em) {
  .show-for-xxxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important; }

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-wrap {
  word-wrap: break-word !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-overline {
  text-decoration: overline !important; }

.text-underline {
  text-decoration: underline !important; }

.text-line-through {
  text-decoration: line-through !important; }

.font-wide {
  letter-spacing: 0.25rem; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic !important; }

ul.list-disc {
  list-style-type: disc !important; }

ul.list-circle {
  list-style-type: circle !important; }

ul.list-square {
  list-style-type: square !important; }

ol.list-decimal {
  list-style-type: decimal !important; }

ol.list-lower-alpha {
  list-style-type: lower-alpha !important; }

ol.list-lower-latin {
  list-style-type: lower-latin !important; }

ol.list-lower-roman {
  list-style-type: lower-roman !important; }

ol.list-upper-alpha {
  list-style-type: upper-alpha !important; }

ol.list-upper-latin {
  list-style-type: upper-latin !important; }

ol.list-upper-roman {
  list-style-type: upper-roman !important; }

.rounded {
  border-radius: 5000px !important; }
  .rounded:after {
    border-radius: 50%; }

.radius {
  border-radius: 0.1875rem; }

.bordered {
  border: 0.0625rem solid #cacaca; }

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent; }

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a; }

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a; }

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.display-block {
  display: block !important; }

.display-table {
  display: table !important; }

.display-table-cell {
  display: table-cell !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.border-box {
  box-sizing: border-box !important; }

.border-none {
  border: none !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin: 0rem; }

.margin-top-0 {
  margin-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-0 {
  padding: 0rem; }

.padding-top-0 {
  padding-top: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin: 1rem; }

.margin-top-1 {
  margin-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-1 {
  padding: 1rem; }

.padding-top-1 {
  padding-top: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin: 2rem; }

.margin-top-2 {
  margin-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-2 {
  padding: 2rem; }

.padding-top-2 {
  padding-top: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin: 3rem; }

.margin-top-3 {
  margin-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-3 {
  padding: 3rem; }

.padding-top-3 {
  padding-top: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.align-left {
  justify-content: flex-start; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-left.vertical.menu > li > a {
  justify-content: flex-start; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .medium-large-order-1 {
    order: 1; }
  .medium-large-order-2 {
    order: 2; }
  .medium-large-order-3 {
    order: 3; }
  .medium-large-order-4 {
    order: 4; }
  .medium-large-order-5 {
    order: 5; }
  .medium-large-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 100em) {
  .xxxlarge-order-1 {
    order: 1; }
  .xxxlarge-order-2 {
    order: 2; }
  .xxxlarge-order-3 {
    order: 3; }
  .xxxlarge-order-4 {
    order: 4; }
  .xxxlarge-order-5 {
    order: 5; }
  .xxxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 48em) {
  .medium-large-flex-container {
    display: flex; }
  .medium-large-flex-child-auto {
    flex: 1 1 auto; }
  .medium-large-flex-child-grow {
    flex: 1 0 auto; }
  .medium-large-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-large-flex-dir-row {
    flex-direction: row; }
  .medium-large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-large-flex-dir-column {
    flex-direction: column; }
  .medium-large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 100em) {
  .xxxlarge-flex-container {
    display: flex; }
  .xxxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxxlarge-flex-dir-row {
    flex-direction: row; }
  .xxxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxxlarge-flex-dir-column {
    flex-direction: column; }
  .xxxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #000000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11; }
  .hamburger--inverted .hamburger-inner {
    background-color: #000000; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #000000; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

*, *::before, *::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  max-width: 120rem; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

b, strong {
  font-weight: 700; }

::selection {
  background-color: #0C5EAA;
  color: #ffffff; }

.button {
  padding: 10px 24px;
  min-height: 3.125rem;
  border-radius: 0px;
  background-color: #204481;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer; }
  .button svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem; }
  .button:hover, .button:focus {
    background-color: #0C5EAA; }
  .button--wide {
    width: 100%;
    justify-content: center; }

body {
  line-height: 1.875em;
  letter-spacing: 0.05em; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1.25em;
  letter-spacing: normal; }

h1, .h1 {
  font-size: 1rem;
  font-weight: 600; }

h2, .h2 {
  font-size: 1rem;
  font-weight: 600; }

h3, .h3 {
  font-size: 1rem;
  font-weight: 600; }

h4, .h4 {
  font-size: 1rem;
  font-weight: 600; }

h5, .h5 {
  font-size: 1rem;
  font-weight: 600; }

h6, .h6 {
  font-size: 1rem;
  font-weight: 600; }

/* stylelint-disable */
.rich-text-area .intro {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 2rem; }

.rich-text-area p {
  margin: 0 0 1rem; }

.rich-text-area h1, .rich-text-area h2, .rich-text-area h3, .rich-text-area h4, .rich-text-area h5, .rich-text-area h6 {
  margin: 2rem 0 0.5rem; }

.rich-text-area a:not(.button) {
  color: #0C5EAA;
  text-decoration: underline; }

.rich-text-area ul {
  margin: initial;
  padding: initial;
  list-style-type: initial;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 17px; }

/* stylelint-enable */
@media print, screen and (min-width: 64em) {
  .site-content {
    width: 100%;
    padding: 50px 0; } }

@media screen and (min-width: 75em) {
  .site-content {
    padding: 100px 0; } }

.sitemap {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .sitemap a:not(.button) {
    color: #0C5EAA;
    text-decoration: underline; }

.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.hero {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%; }
  @media print, screen and (min-width: 40em) {
    .hero {
      position: fixed;
      width: calc((100vw / (24 / 8)) - 20px); } }
  @media print, screen and (min-width: 64em) {
    .hero {
      width: calc((100vw / (24 / 11)) - 20px); } }
  .hero-wrapper {
    display: none; }
    @media print, screen and (min-width: 48em) {
      .hero-wrapper {
        display: flex;
        height: 100%; } }
  .hero__bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%; }
    @media print, screen and (min-width: 40em) {
      .hero__bg {
        box-shadow: inset 0px 140px 200px -70px white; } }
  .hero--mobile {
    position: relative;
    margin-left: -.625rem;
    margin-right: -.625rem;
    margin-bottom: 20px; }
    .hero--mobile .hero__bg {
      position: relative;
      padding-top: 56.2%;
      width: 100%; }
    @media print, screen and (min-width: 40em) {
      .hero--mobile {
        display: none;
        width: 100%;
        margin: 0; } }
  @media print, screen and (min-width: 40em) {
    .hero--backoffice {
      display: block;
      margin: 0;
      width: 100%; } }
  @media print, screen and (min-width: 40em) {
    .hero--backoffice {
      display: block; } }

/* stylelint-disable */
.fetchimage-wrapper {
  position: relative;
  height: 100%;
  z-index: 10; }
  .fetchimage-wrapper .button--delete {
    bottom: 20px !important;
    top: auto !important; }

/* stylelint-enable */
input, select {
  height: 3.125rem;
  border: 1px solid #cccccc;
  padding: 0 1rem;
  width: 100%;
  transition: all 0.3s ease-in-out; }
  input:focus, select:focus {
    outline: none;
    border: 1px solid #000000; }

textarea {
  border: 1px solid #cccccc;
  padding: 8px 16px;
  resize: vertical;
  min-height: 6.25rem;
  width: 100%;
  transition: border .3s ease-in-out; }
  textarea:focus {
    outline: none;
    border: 1px solid #000000; }

select {
  background: #ffffff;
  appearance: none; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 2.5rem;
    color: #cccccc;
    border: 1px solid #cccccc;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 1px solid #cccccc; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #000000;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
      opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    min-width: 1.5625rem;
    min-height: 1.5625rem;
    margin-right: 0.5rem;
    cursor: pointer; }
    .checkbox-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 0.3125rem;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .checkbox-wrapper__input-dot {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
    padding: 0.25rem; }
    .checkbox-wrapper__input-dot svg {
      fill: #0C5EAA;
      width: 100%;
      display: flex;
      vertical-align: middle;
      height: initial; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot {
      opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    margin-top: 0.125rem;
    font-weight: 400; }

.form-message {
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media print, screen and (min-width: 40em) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    margin-bottom: 1.5rem; }
    .form__group:last-of-type {
      margin-bottom: 0; }
  .form__invalid-message {
    color: #e50044; }
  .form__label {
    display: block;
    color: #0C5EAA;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase; }
  .form--loading {
    opacity: .5; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  background: #ffffff;
  height: auto;
  border-bottom: 1px solid #cccccc;
  overflow: auto;
  max-height: 100vh;
  z-index: 15; }
  @media print, screen and (min-width: 48em) {
    .header {
      margin-top: 20px;
      background: none;
      padding: 0;
      border-bottom: none;
      overflow: visible; } }
  @media print, screen and (min-width: 48em) and (min-width: 64em) {
    .header {
      margin-top: 35px; } }
  @media print, screen and (min-width: 48em) {
      .header--scrolled {
        margin-top: 0;
        background: #ffffff;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 0;
        padding-top: 10px;
        /* stylelint-disable */
        /* stylelint-enable */ }
        .header--scrolled .menu-item--active .menu-item__link::before {
          margin-bottom: 15px; } }
      @media print, screen and (min-width: 48em) and (min-width: 64em) {
        .header--scrolled .menu-item--active .menu-item__link::before {
          margin-bottom: 13px; } }
  .header.menu-expanded {
    bottom: 0; }
    .header.menu-expanded .navigation-wrapper {
      height: auto;
      opacity: 1; }

footer {
  margin-bottom: 20px;
  margin-top: 50px; }

.footer-content {
  font-size: 0.8125rem;
  line-height: 1.55em;
  letter-spacing: 0.16em; }
  .footer-content a:not(.btn) {
    color: #0C5EAA;
    text-decoration: underline; }

.footer-menu .kvk {
  margin-right: 1.75em;
  font-size: 0.8125rem; }
  .footer-menu .kvk::after {
    content: "\2022";
    display: inline-block;
    font-size: 1rem;
    margin-left: 1em; }

.footer-menu .menu-item {
  display: inline-block;
  padding: 0; }
  .footer-menu .menu-item a {
    color: #0C5EAA; }
    .footer-menu .menu-item a::before {
      content: none; }

.main {
  padding-top: 60px;
  max-width: 100%;
  min-height: 100vh;
  height: 1px; }
  @media print, screen and (min-width: 40em) {
    .main {
      padding-top: 120px; } }
  @media print, screen and (min-width: 40em) {
    .main__inner {
      height: 100%; } }

.cc-banner .cc-compliance {
  display: inline-block; }
  .cc-banner .cc-compliance .cc-btn {
    display: inline-block; }

/* stylelint-disable */
.cc-revoke {
  display: block !important; }

/* stylelint-enable */
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.logo {
  display: inline-block;
  max-width: 149px;
  width: 100%;
  margin-right: 10px; }
  @media print, screen and (min-width: 40em) {
    .logo {
      max-width: 125px;
      margin-right: 20px; } }
  @media print, screen and (min-width: 64em) {
    .logo {
      max-width: 205px;
      margin-right: 10px; } }

.navigation {
  display: inline-block;
  margin-top: 20px; }
  @media print, screen and (min-width: 48em) {
    .navigation {
      margin-top: 0; } }
  .navigation-wrapper {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s ease-in-out; }
    @media print, screen and (min-width: 48em) {
      .navigation-wrapper {
        height: auto;
        overflow: visible;
        opacity: 1; } }
  .navigation-top {
    display: flex;
    padding: 12px 0; }
    @media print, screen and (min-width: 48em) {
      .navigation-top {
        padding: 0; } }

.menu-item {
  display: block;
  padding: 15px 0; }
  @media print, screen and (min-width: 48em) {
    .menu-item {
      display: inline-block;
      padding: 10px 10px 21px; } }
  @media print, screen and (min-width: 64em) {
    .menu-item {
      padding: 8px 25px 23px; } }
  .menu-item__link {
    color: #000000;
    display: inline-block;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.875rem; }
  .menu-item--active .menu-item__link {
    position: relative;
    color: #0C5EAA; }
    .menu-item--active .menu-item__link::before {
      transition: all 0.3s ease-in-out;
      border-top: 6px solid #0C5EAA;
      top: 100%;
      content: "";
      left: 0;
      position: absolute;
      right: 0; }
  @media print, screen and (min-width: 48em) {
    .menu-item--active .menu-item__link::before, .menu-item:hover .menu-item__link::before {
      top: auto;
      bottom: 100%;
      margin-bottom: 25px; } }
  @media print, screen and (min-width: 48em) and (min-width: 64em) {
    .menu-item--active .menu-item__link::before, .menu-item:hover .menu-item__link::before {
      margin-bottom: 38px; } }

.hamburger {
  display: inline-flex;
  align-items: center; }
  .hamburger-label {
    color: #0C5EAA;
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 0.875rem;
    font-weight: 800; }

.language-switch {
  margin-top: 50px;
  position: relative; }
  @media print, screen and (min-width: 48em) {
    .language-switch {
      margin-top: 8px;
      margin-right: 10px; } }
  @media print, screen and (min-width: 64em) {
    .language-switch {
      margin-top: 4px; } }
  .language-switch .flag {
    display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .language-switch .flag {
        width: 25px; } }
    @media print, screen and (min-width: 64em) {
      .language-switch .flag {
        width: 40px; } }
    @media print, screen and (min-width: 48em) {
      .language-switch .flag.active {
        margin-right: -10px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1; } }
