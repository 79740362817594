.button {
    padding: 10px 24px;
    min-height: rem-calc(50);
    border-radius: 0px;
    background-color: map-deep-get($colors, "primary", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "primary", "default");
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }
}