.menu-item {
    $item: &;
    display: block;
    padding: 15px 0;

    @include breakpoint(medium-large) {
        display: inline-block;
        padding: 10px 10px 21px;
    }

    @include breakpoint(large) {
        padding: 8px 25px 23px;
    }

    &__link {
        $link: &;
        color: map-deep-get($colors, "black", "default");
        display: inline-block;
        font-weight: 800;
        text-transform: uppercase;
        font-size: rem-calc(14px);
    }

    &--active {
        #{$item + "__link"} {
            position: relative;
            color: map-deep-get($colors, "primary", "default");

            &::before {
                transition: $transition-default;
                border-top: 6px solid map-deep-get($colors, "primary", "default");
                top: 100%;
                content: "";
                left: 0;
                position: absolute;
                right: 0;
            }
        }
    }

    @include breakpoint(medium-large) {
        &--active,
        &:hover {
            #{$item + "__link"} {
                &::before {
                    top: auto;
                    bottom: 100%;
                    margin-bottom: 25px;

                    @include breakpoint(large) {
                        margin-bottom: 38px;
                    }
                }
            }
        }
    }
}