.language-switch {
    margin-top: 50px;
    position: relative;

    @include breakpoint(medium-large) {
        margin-top: 8px;
        margin-right: 10px;
    }

    @include breakpoint(large) {
        margin-top: 4px;
    }

    .flag {
        display: inline-block;

        @include breakpoint(medium) {
            width: 25px;
        }

        @include breakpoint(large) {
            width: 40px;
        }

        &.active {
            @include breakpoint(medium-large) {
                margin-right: -10px;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
}