.cc-banner .cc-compliance{
    display: inline-block;

    .cc-btn{
        display: inline-block;
    }
}

/* stylelint-disable */
// Externe js overschrijven
.cc-revoke{
    display: block !important;
}
/* stylelint-enable */