// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    .intro {
        font-size: rem-calc(18);
        margin-top: 0;
        margin-bottom: rem-calc(32);
    }

    p {
        margin: 0 0 rem-calc(16);
    }

    h1, h2, h3, h4, h5, h6 {
        margin: rem-calc(32) 0 rem-calc(8);
    }

    a:not(.button) {
        color: map-deep-get($colors, "primary", "default");
        text-decoration: underline;
    }

    ul {
        margin: initial;
        padding: initial;
        list-style-type: initial;

        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 17px;
    }
}

/* stylelint-enable */