.hero{
    $hero: &;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;

    @include breakpoint(medium) {
        position: fixed;
        width: calc((100vw / (24 / 8)) - #{map-get($grid-margin-gutters, 'medium')});
    }

    @include breakpoint(large) {
        width: calc((100vw / (24 / 11)) - #{map-get($grid-margin-gutters, 'medium')});

    }

    &-wrapper{
        display: none;

        @include breakpoint(medium-large) {
            display: flex;
            height: 100%;
        }
    }

    &__bg {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        @include breakpoint(medium) {
            box-shadow: inset 0px 140px 200px -70px white;
        }
    }

    &--mobile{
        position: relative;
        margin-left: -.625rem;
        margin-right: -.625rem;
        margin-bottom: 20px;

        #{$hero + '__bg'}{
            position: relative;
            padding-top: 56.2%;
            width: 100%;
        }

        @include breakpoint(medium) {
            display: none;
            width: 100%;
            margin: 0;
        }
    }

    &--backoffice {
        @include breakpoint(medium) {
            display: block;
            margin: 0;
            width: 100%;
        }
    }

    &--backoffice {
        @include breakpoint(medium) {
            display: block;
        }
    }
}